import React, { Fragment } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.scss";
import Loader from "./layouts/Loader/Loader";

const Landingpageapp = React.lazy(() => import("./components/Landingpageapp"));
const LandingDocumentSummary = React.lazy(() => import("./components/LandingPage/LandingDocumentSummary"));

const AuthLogin = React.lazy(() => import("./Authentication/Login"));
const AuthForgot = React.lazy(() => import("./Authentication/Forgot"));
const AuthSignup = React.lazy(() => import("./Authentication/Signup"));

const App = React.lazy(() => import("./components/app"));
const Contact = React.lazy(() => import("./components/Contact/Contact"));
const Privacy = React.lazy(() => import("./components/Privacy/Privacy"));

const AdminDashboard = React.lazy(() => import("./components/Dashboard/AdminDashboard"));
const AdminAllDocuments = React.lazy(() => import("./components/Documents/AdminAllDocuments"));
const AdminAccount = React.lazy(() => import("./components/Account/AdminAccount"));

const UserDashboard = React.lazy(() => import("./components/Dashboard/UserDashboard"));
const UserBundles = React.lazy(() => import("./components/Bundles/UserBundles"));
const UserAccount = React.lazy(() => import("./components/Account/UserAccount"));
const SubscribedComplete = React.lazy(() => import("./components/Checkout/SubscribedComplete"));
const Support = React.lazy(() => import("./components/Support/Support"));

const ThirdPartyDashboard = React.lazy(() => import("./components/Dashboard/ThirdPartyDashboard"));
const ThirdPartyBundles = React.lazy(() => import("./components/Bundles/ThirdPartyBundles"));
const ThirdPartyDocuments = React.lazy(() => import("./components/Documents/ThirdPartyDocuments"));
const ThirdPartyDocumentApproval = React.lazy(() => import("./components/DocumentApproval/ThirdPartyDocumentApproval"));
const ThirdPartyAccount = React.lazy(() => import("./components/Account/ThirdPartyAccount"));

const Root = () => {
  return (
    <Fragment>
      <BrowserRouter>
        <React.Suspense fallback={<Loader />}>
          <Routes>
          <Route index path={`${process.env.PUBLIC_URL}/`} element={<Landingpageapp />} />
            <Route path={`${process.env.PUBLIC_URL}/document-summary`} element={<LandingDocumentSummary />} />
            <Route
              path={`${process.env.PUBLIC_URL}/contact`}
              element={<Contact />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/privacy`}
              element={<Privacy />}
            />
            <Route element={<AuthLogin />} />
            <Route
              path={`${process.env.PUBLIC_URL}/login`}
              element={<AuthLogin />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/forgot`}
              element={<AuthForgot />}
            />
            <Route
              path={`${process.env.PUBLIC_URL}/register`}
              element={<AuthSignup />}
            />
            <Route path={`${process.env.PUBLIC_URL}/`}
              element={<App />}>
              <Route>
                <Route
                  path={`${process.env.PUBLIC_URL}/user-dashboard`}
                  element={<UserDashboard />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/user-bundles`}
                  element={<UserBundles />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/subscribed-complete`}
                  element={<SubscribedComplete />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/support`}
                  element={<Support />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/user-account`}
                  element={<UserAccount />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/thirdparty-dashboard`}
                  element={<ThirdPartyDashboard />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/thirdparty-bundles`}
                  element={<ThirdPartyBundles />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/thirdparty-documents`}
                  element={<ThirdPartyDocuments />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/thirdparty-document-approval`}
                  element={<ThirdPartyDocumentApproval />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/thirdparty-account`}
                  element={<ThirdPartyAccount />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/admin-dashboard`}
                  element={<AdminDashboard />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/admin-all-documents`}
                  element={<AdminAllDocuments />}
                />
                <Route
                  path={`${process.env.PUBLIC_URL}/admin-account`}
                  element={<AdminAccount />}
                />
              </Route>
            </Route>
          </Routes>
        </React.Suspense>
      </BrowserRouter>
    </Fragment>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);